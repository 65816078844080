import axios from 'axios';
import {
  API_ENDPOINTS,
  Button,
  ButtonTypes,
  customToast,
  PageLayout,
  PostcodeLookup,
  route,
  StringHelpers,
  SubTitle,
  useForm,
  BottomAnchoredControls,
  useLangContext,
  Variants,
  WidthConstrainedContainer,
  Card,
  CardBody,
  useDisabledContext,
  PhoneNumberField,
  Spinner,
} from 'carrier-fe';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const defaultData = {
  first_name: '',
  last_name: '',
  email: '',
  landline_phone_prefix: '',
  landline_phone_number: '',
  mobile_phone_prefix: '',
  mobile_phone_number: '',
  company_name: '',
  address_line_1: '',
  address_line_2: '',
  address_line_3: '',
  town_city: '',
  state_county: '',
  postcode_zipcode: '',
  local_authority_code: '',
  country_code_iso_3: '',
};

type Data = typeof defaultData;

// HANDLES API REQUESTS ERRORS
const handleError = (
  error: any,
  defaultMessage: string = 'An error occurred. Please refresh the page to try again.'
) => {
  const message = error?.response?.data?.message || defaultMessage;
  customToast({
    title: message,
    variant: Variants.Danger,
  });
};

// MAIN COMPONENT
function CustomerCompanyForm() {
  const { customerId } = useParams();
  const isEdit = customerId ? true : false;
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { getLangPage, crud,fields } = useLangContext();
  const [lang, setLang] = useState<any>(null);
  const [phonePrefixes, setPhonePrefixes] = useState<any>(null);
  const { disabled, setDisabled } = useDisabledContext();

  useEffect(() => {
    load();
  }, []);

  // INITIAL LOAD
  const load = async () => {
    setIsLoading(true);
    try {
      // GET LANG
      if (!lang) {
        const res = await getLangPage('admin/generic');
        setLang(res || []);
      }
      // GET PHONE PREFIXES
      if (!phonePrefixes) {
        const res = await axios.get(
          route(API_ENDPOINTS.GENERIC.SELECT.PHONE_PREFIXES)
        );
        setPhonePrefixes(res.data.data || []);
      }

      if (isEdit) {
        const res = await axios.get<{ data: Data }>(
          route(API_ENDPOINTS.REGISTRATION.CUSTOMER.EDIT, {
            customerId: String(customerId),
          })
        );
        if (res?.data?.data) {
          setValues(res.data.data);
        }
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const update = async (payload: typeof defaultData) => {
    return isEdit
      ? axios.patch<{ data: Data; message: string }>(
          route(API_ENDPOINTS.REGISTRATION.CUSTOMER.UPDATE, {
            customerId: String(customerId),
          }),
          payload
        )
      : axios.post<{ data: Data; message: string }>(
          route(API_ENDPOINTS.REGISTRATION.CUSTOMER.STORE),
          payload
        );
  };

  const { Input, Submit, errors, store, setValues, data, isSuccess } = useForm(
    update,
    defaultData
  );

  // REDIRECT TO VIEW WHEN CREATED / UPDATED
  useEffect(() => {
    if (isSuccess && data) {
      const { id } = data;
      navigate(`/customers/${id}`);
    }
  }, [isSuccess]);

  return (
    <PageLayout
      title={
        isEdit
          ? StringHelpers.replaceFirst(
              crud?.pages?.edit?.title,
              ':model',
              crud?.models?.customer
            )
          : StringHelpers.replaceFirst(
              crud?.pages?.create?.title,
              ':model',
              crud?.models?.customer
            )
      }
    >
      <WidthConstrainedContainer>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center vh-100">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="m-0 p-0">
              <div className="pe-xxl-4">
                <Card>
                  <CardBody className={'pb-0'}>
                    <SubTitle
                      title={StringHelpers.title(
                        crud?.sub_titles?.details || 'Details'
                      )}
                      className="mb-3 w-100"
                      style={{ color: '#464C5E' }}
                    />
                    <div className="row">
                      <div className="col-12 col-md-6">
                        {Input({
                          name: 'first_name',
                          label: StringHelpers.title(
                            fields?.first_name || 'First Name'
                          ),
                        })}
                      </div>
                      <div className="col-12 col-md-6">
                        {Input({
                          name: 'last_name',
                          label: StringHelpers.title(
                            fields?.last_name || 'Last Name'
                          ),
                        })}
                      </div>
                    </div>

                    {Input({
                      name: 'email',
                      label: StringHelpers.title(
                        fields?.email || 'Email'
                      ),
                      type: 'email',
                    })}

                    {/* Mobile Details */}
                    <div className="col-12 mb-3">
                      <PhoneNumberField
                        noMargin
                        label={StringHelpers.title(
                          fields.mobile_phone_number || 'Mobile number'
                        )}
                        name="mobile_phone_number"
                        value={store?.mobile_phone_number}
                        onChange={(value) =>
                          setValues({
                            mobile_phone_number: value,
                          })
                        }
                        disabled={disabled}
                        errorMessages={
                          !!errors && !!errors.mobile_phone_number
                            ? errors.mobile_phone_number
                            : null
                        }
                        prefixValue={store?.mobile_phone_prefix}
                        prefixOnChange={(value) =>
                          setValues({
                            mobile_phone_prefix: value,
                          })
                        }
                      />
                    </div>

                    {/* Landline Details */}
                    <div className="col-12 mb-3">
                      <PhoneNumberField
                        noMargin
                        label={StringHelpers.title(
                          fields.landline_phone_number ||
                            'Landline number'
                        )}
                        name="landline_phone_number"
                        value={store?.landline_phone_number}
                        onChange={(value) =>
                          setValues({
                            landline_phone_number: value,
                          })
                        }
                        disabled={disabled}
                        errorMessages={
                          !!errors && !!errors.landline_phone_number
                            ? errors.landline_phone_number
                            : null
                        }
                        prefixValue={store?.landline_phone_prefix}
                        prefixOnChange={(value) =>
                          setValues({
                            landline_phone_prefix: value,
                          })
                        }
                      />
                    </div>

                    <SubTitle
                        title={StringHelpers.title(
                            crud?.sub_titles?.company || 'Company'
                        )}
                        className="mb-3 w-100"
                        style={{ color: '#464C5E' }}
                    />
                    {/* Company Details Form */}
                    {Input({
                      name: 'company_name',
                      label: StringHelpers.title(
                          fields?.company_name || 'Company Name'
                      ),
                    })}
                  </CardBody>
                </Card>
              </div>
              <div className="pe-xxl-4">
                <Card>
                  <CardBody>
                    <PostcodeLookup
                      data={store}
                      setData={setValues}
                      errors={errors}
                    />
                  </CardBody>
                </Card>
              </div>
            </div>

            {/* Submit Button */}
            <BottomAnchoredControls>
              <Button
                label={crud.buttons.back.default}
                onClick={() => navigate('/customers')}
                variant={Variants.Dark}
                type={ButtonTypes.Outline}
                className={'me-4'}
                disabled={disabled}
              />
              <Submit
                defaultLabel={
                  isEdit
                    ? crud?.buttons?.edit?.default || 'Update'
                    : crud?.buttons?.create?.default || 'Create'
                }
                loadingLabel={
                  isEdit
                    ? crud?.buttons?.edit?.submitting || 'Updating...'
                    : crud?.buttons?.create?.submitting || 'Creating...'
                }
              />
            </BottomAnchoredControls>
          </>
        )}
      </WidthConstrainedContainer>
    </PageLayout>
  );
}

export default CustomerCompanyForm;
